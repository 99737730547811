import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Paragraph, Page, ExternalLink, Header } from '../components'

function DeepRedirect() {
  const location = useLocation()
  const deepPath = location.pathname.split('/deep/', 2)[1]
  const deepUrl = `wahtalk:${deepPath}${location.search}`

  useEffect(() => {
    window.location.href = deepUrl
  }, [deepUrl]);

  return (
    <Page>
      <Header headline="Redirect into the WahTalk app" />
      <Paragraph>
        Redirecting to <ExternalLink to={deepUrl}>{deepUrl}</ExternalLink>...
      </Paragraph>
      <Paragraph>
        If you are not being redirected, please make sure you have the WahTalk app installed on this device and try opening this URL in a different browser.
      </Paragraph>
    </Page>
  );
}

export default DeepRedirect
