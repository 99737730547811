import { Link } from 'react-router-dom'
import logo from './screaming-logo.svg'
import appStoreBadge from './app-store-badge.svg'
import googlePlayBadge from './google-play-badge.png'
import {
  ROOT_PATH,
  APP_STORE_URL,
  PLAY_STORE_URL,
} from './constants'

const FONT_SIZE_REGULAR = '.85rem'
const COLOR_REGULAR = 'rgb(225, 255, 218)'
const COLOR_PROMINENT = 'rgb(182, 251, 150)'
const COLOR_COLORFUL = '#00FF31'

export function Logo({ size }) {
  return (
    <img
      src={logo}
      alt="WahTalk logo 'Screaming'"
      width={size}
    />
  )
}

export function AppStoreBadge({ size }) {
  const padding = size * 0.06

  return (
    <div style={{ padding, width: size - (2 * padding) }}>
      <ExternalLink to={APP_STORE_URL}>
        <img
          src={appStoreBadge}
          alt="App Store badge"
          width="100%"
        />
      </ExternalLink>
    </div>
  )
}

export function PlayStoreBadge({ size }) {
  return (
    <ExternalLink to={PLAY_STORE_URL}>
      <img
        src={googlePlayBadge}
        alt="Play Store badge"
        width={size}
      />
    </ExternalLink>
  )
}

export function Page({ children }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: 700, padding: '0 10px' }}>
        {children}
      </div>
    </div>
  )
}

export function Headline({ children, level = 1 }) {
  const style = {
    color: COLOR_PROMINENT,
    marginTop: 30,
  }

  switch (level) {
    case 2:
      return <h2 style={style}>{children}</h2>
    case 3:
      return <h3 style={style}>{children}</h3>
    case 4:
      return <h4 style={style}>{children}</h4>
    default:
      return <h1 style={style}>{children}</h1>
  }
}

export function Header({ headline }) {
  return (
    <header style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
        <LocalLink to={ROOT_PATH}>
          <Logo size={150} />
        </LocalLink>
      </div>
      <Headline>{headline}</Headline>
    </header>
  )
}

export function Bold({ children }) {
  return (
    <b style={{ color: COLOR_PROMINENT }}>
      {children}
    </b>
  )
}

export function Italic({ children }) {
  return (
    <i style={{ color: COLOR_PROMINENT }}>
      {children}
    </i>
  )
}

export function Footer({ version }) {
  return (
    <footer
      style={{
        fontSize: '0.55rem',
        marginTop: 100,
        marginBottom: 20,
        color: 'rgb(27, 159, 22)',
        textAlign: 'center',
      }}
    >
      WahTalk · {version}
    </footer>
  )
}

export function Paragraph({ children }) {
  return (
    <p style={{ fontSize: FONT_SIZE_REGULAR, color: COLOR_REGULAR }}>
      {children}
    </p>
  )
}

export function LocalLink({ children, to }) {
  return (
    <Link to={to} style={{ color: COLOR_COLORFUL, fontSize: FONT_SIZE_REGULAR }}>{children}</Link>
  )
}

export function ExternalLink({ children, to }) {
  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer"
      style={{ color: COLOR_COLORFUL, fontSize: FONT_SIZE_REGULAR }}
    >
      {children}
    </a>
  )
}

export function EmailLink({ email }) {
  return (
    <a
      href={`mailto:${email}`}
      style={{ color: COLOR_COLORFUL, fontSize: FONT_SIZE_REGULAR }}
    >
      {email}
    </a>
  )
}

export function UnorderedList({ children }) {
  return (
    <ul style={{ fontSize: FONT_SIZE_REGULAR, color: COLOR_REGULAR }}>
      {children?.length ? (
        <>
          {children.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </>
      ) : (
        <li>{children}</li>
      )}
    </ul>
  )
}

export function OrderedList({ children }) {
  return (
    <ol style={{ fontSize: FONT_SIZE_REGULAR, color: COLOR_REGULAR }}>
      {children?.length ? (
        <>
          {children.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </>
      ) : (
        <li>{children}</li>
      )}
    </ol>
  )
}
