import {
  Page,
  Header,
  Paragraph,
  Footer,
  EmailLink,
} from '../components'

function Support() {
  return (
    <Page>
      <Header headline="Support" />
      <article>
        <Paragraph>
          If you have any problems with our app, criticism or concerns, please write us at <EmailLink email="wahtalk@posteo.net" />.
        </Paragraph>
      </article>
      <Footer version="2021-06-04" />
    </Page>
  )
}

export default Support
