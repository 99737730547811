import {
  Page,
  Header,
  Paragraph,
  AppStoreBadge,
  PlayStoreBadge,
  Headline,
} from '../components'

function Root() {
  return (
    <Page>
      <Header headline="WahTalk: Meet people - not profiles" />
      <Headline level={2}>
        Invite anyone nearby for a 5-minute, in-person talk - right there and then
      </Headline>
      <Paragraph>
        Get notified when you cross people ready for a talk throughout your day or go to one of many WahTalk Meeting Areas to have plenty of people to choose from.
      </Paragraph>
      <Headline level={2}>
        You liked the person you've met?
      </Headline>
      <Paragraph>
        Tell the app that you would like to stay in touch at any time after a talk. If it's mutual, you'll see each other's contact details.
      </Paragraph>
      <Headline level={2}>
        Now available for Android and iOS
      </Headline>
      <div style={{ marginTop: 25, marginBottom: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <AppStoreBadge size={250} />
        <PlayStoreBadge size={250} />
      </div>
    </Page>
  )
}

export default Root
