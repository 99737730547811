import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { LocalLink } from './components'
import './App.css'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import SupportPage from './pages/Support'
import TermsOfUsePage from './pages/TermsOfUse'
import DeepRedirect from './pages/DeepRedirect'
import Root from './pages/Root'
import HowToDeleteAccount from './pages/HowToDeleteAccount'
import {
  PRIVACY_POLICY_PATH,
  SUPPORT_PATH,
  TERMS_OF_USE_PATH,
  DEEP_REDIRECT_PATH,
  HOW_TO_DELETE_ACCOUNT_PATH,
  ROOT_PATH,
} from './constants'

function App() {
  return (
    <Router>
      <div>
        <nav style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <div>
            <LocalLink to={SUPPORT_PATH}>Support</LocalLink>
          </div>
          <div style={{ marginLeft: 10 }}>
            <LocalLink to={TERMS_OF_USE_PATH}>Terms of Use</LocalLink>
          </div>
          <div style={{ marginLeft: 10 }}>
            <LocalLink to={PRIVACY_POLICY_PATH}>Privacy Policy</LocalLink>
          </div>
        </nav>
        <Switch>
          <Route path={SUPPORT_PATH}>
            <SupportPage />
          </Route>
          <Route path={PRIVACY_POLICY_PATH}>
            <PrivacyPolicyPage />
          </Route>
          <Route path={TERMS_OF_USE_PATH}>
            <TermsOfUsePage />
          </Route>
          <Route path={HOW_TO_DELETE_ACCOUNT_PATH}>
            <HowToDeleteAccount />
          </Route>
          <Route path={DEEP_REDIRECT_PATH}>
            <DeepRedirect />
          </Route>
          <Route path={ROOT_PATH}>
            <Root />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
