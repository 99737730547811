import {
  OrderedList,
  Page,
  Header,
  EmailLink,
  Paragraph,
  Footer,
} from '../components'

function HowToDeleteAccount() {
  return (
    <Page>
      <Header headline="Deleting your WahTalk account" />
      <Paragraph>
        We regret that WahTalk doesn't work out for you. We would appreciate if you would tell us more about your reasons at <EmailLink email="wahtalk@posteo.net" />.
      </Paragraph>
      <Paragraph>
        In order to delete your WahTalk account, follow these steps:
      </Paragraph>
      <OrderedList>
        <>
          Open the app
        </>
        <>
          Tap the "Settings" button
        </>
        <>
          Scroll all the way down and tap "Delete account"
        </>
        <>
          Confirm your decision in the dialog window. Note that this action can not be undone.
        </>
        <>
          Your account is now deleted and you can no longer be seen or interacted with by other users. Most of your personal information was deleted immediately and none will be held onto more than 90 days from the date of account deletion. Your interactions with other users might remain visible to those users you interacted with, however no longer associated with personal information such as name, photo, email or phone number ("anonymized").
        </>
      </OrderedList>
      <Footer version="2024-03-22" />
    </Page>
  );
}

export default HowToDeleteAccount
